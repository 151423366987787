<template>
  <div class="wrapper">
    <div data-v-247f0ef8="">
      <p style="padding: 16px 0px 0px 16px">
        更新日期：2019年10月31日
      </p>
      <p style="padding: 0px 0px 16px 16px">
        生效日期：2019年10月31日
      </p>
      <p
        style="
          padding: 0px 16px 8px;
          text-indent: 2em;
          text-align: justify;
          font-size: 14px;
        "
      >
        BIMCC数字建造是一款由重庆市筑云科技有限责任公司（以下简称“我们”）研发和运营的工程管理产品。我们将通过《BIMCC数字建造隐私保护协议》帮助您了解BIMCC数字建造如何收集、使用和存储您的个人信息及您享有何种权利。其中要点如下：
      </p>
      <ul>
        <li>
          i.
          我们将逐一说明我们收集的您的个人信息类型及其对应的用途，以便您了解我们针对某一特定功能所收集的具体个人信息的类别、使用理由及收集方式。
        </li>
        <li>
          ii.
          当您使用一些功能时，我们会在获得您的同意后，收集您的一些敏感信息，例如您在使用BIMCC数字建造的考勤功能时，我们会收集您的地理位置信息。除非按照相关法律法规要求必须收集，拒绝提供这些信息仅会使您无法使用相关特定功能，但不影响您正常使用BIMCC数字建造的其他功能。
        </li>
        <li>
          iii.
          BIMCC数字建造不会主动共享或转让您的个人信息至重庆市筑云科技有限责任公司外的第三方。如存在其他共享或转让您的个人信息，或着您需要我们将您的个人信息共享或转让至重庆市筑云科技有限责任公司外的第三方的情形，我们会直接或确认第三方征得您对上述行为的明示同意。此外，我们会对对外提供信息的行为进行风险评估。
        </li>
      </ul>
      <div style="padding: 16px">
        <p class="name">1.我们收集的信息</p>
        <p class="comment" style="text-indent: 2em">
          在您使用BIMCC数字建造服务的过程中，BIMCC数字建造会按照如下方式收集您在使用服务时主动提供或因为使用服务而产生的信息，用以向您提供服务、优化我们的服务以及保障您的帐号安全：
        </p>
        <ul>
          <li>
            <p>1.1</p>
            <p style="text-indent: 2em">
              当您注册BIMCC数字建造帐号时，我们会收集您的姓名、职位、就职公司、手机号码。收集这些信息是为了帮助您完成BIMCC数字建造帐号的注册，保护您的帐号安全。手机号码属于敏感信息，收集此类信息是为了满足相关法律、法规关于网络实名制要求。若您不提供这类信息，您可能无法正常使用我们的服务。您还可以根据自身需求选择填写地区等信息。
            </p>
          </li>
          <li>
            <p>1.2</p>
            <p style="text-indent: 2em">
              当您使用BIMCC数字建造服务时，为保障您正常使用我们的服务，维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的帐号安全，我们会收集您的设备型号、操作系统、唯一设备标识符、登陆IP地址、BIMCC数字建造软件版本号、接入网络的方式、类型和状态、网络质量数据、操作日志、服务日志信息（如您在阅读功能下的浏览历史、服务故障信息等信息）等日志信息，这类信息是为提供服务必须收集的基础信息。
            </p>
          </li>
          <li>
            <p>1.3</p>
            <p style="text-indent: 2em">
              当您使用BIMCC数字建造上传数据时（包括但不限于图片、视频、word、excle、PDF、PPT、.svf、.szfzip、jpg、.exe）、拍摄或编辑图片或视频时，为了向您提供该服务，我们会在获得您的明示同意后，访问您的相册、麦克风，或将您的地理位置信息和/或其对应地理位置的天气状况以水印的形式保存至您拍摄、编辑的照片中。上述信息属于敏感信息，拒绝提供该信息不影响您正常使用BIMCC数字建造的其他功能。
            </p>
          </li>
          <li>
            <p>1.4</p>
            <p style="text-indent: 2em">
              当您使用BIMCC数字建造质量巡检、安全巡检、质量亮点、安全亮点等功能时，您发布的文字、照片、视频等信息以及互动信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。除非经您自主选择或遵从相关法律法规要求或服务协议的约定，我们不会对外提供上述信息，或者将其用于该功能以外的其他用途。
            </p>
          </li>
        </ul>
      </div>
      <div style="padding: 16px">
        <p class="name">2.信息的存储</p>
        <p class="comment" style="text-indent: 2em"></p>
        <ul>
          <li>
            <p>2.1 信息存储的地点:</p>
            <p style="text-indent: 2em">
              我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。目前我们不会跨境传输或存储您的个人信息。将来如需跨境传输或存储的，我们会向您告知信息出境的目的、接收方、安全保证措施和安全风险，并征得您的同意。
            </p>
          </li>
          <li>
            <p>2.2 信息存储的方式和期限:</p>
            <p style="text-indent: 2em">
              手机号码：若您需要使用BIMCC数字建造帐号服务，我们需要一直保存您的手机号码，以保证您正常使用该服务，当您注销帐号后，我们将删除帐号的信息，但不会删除帐号产生的历史数据。
            </p>
          </li>
        </ul>
      </div>
      <div style="padding: 16px">
        <p class="name">3.信息安全</p>
        <p class="comment" style="text-indent: 2em"></p>
        <ul>
          <li>
            <p>3.1 安全保护措施:</p>
            <p style="text-indent: 2em">
              我们努力为用户的信息安全提供保障，以防止信息的泄露、丢失、不当使用、未经授权访问和披露等。我们使用多方位的安全保护措施，以确保用户的个人信息保护处于合理的安全水平，包括技术保护手段、管理制度控制、安全体系保障等诸多方面。此外，我们还通过了国家网络安全等级保护（三级）的测评和备案。我们采用业界领先的技术保护措施。我们使用的技术手段包括但不限于防火墙、加密（例如SSL）、去标识化或匿名化处理、访问控制措施等。此外，我们还会不断加强安装在您设备端的软件的安全能力。例如，我们会在您的设备本地完成部分信息加密工作，以巩固安全传输。我们建立了保障个人信息安全的管理制度、流程和组织。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务并进行审计，违反义务的人员会根据规定进行处罚。我们也会审查该管理制度、流程和组织，以防未经授权的人员擅自访问、使用或披露用户的信息。
            </p>
          </li>
          <li>
            <p>3.2 安全事件处置措施:</p>
            <p style="text-indent: 2em">
              若发生个人信息泄露、损毁、丢失等安全事件，我们会启动应急预案，阻止安全事件扩大。安全事件发生后，我们会及时以推送通知、邮件等形式告知您安全事件的基本情况、我们即将或已经采取的处置措施和补救措施，以及我们对您的应对建议。如果难以实现逐一告知，我们将通过公告等方式发布警示。
            </p>
          </li>
        </ul>
      </div>
      <div style="padding: 16px">
        <p class="name">4.我们如何使用信息</p>
        <p class="comment" style="text-indent: 2em">
          我们严格遵守法律法规的规定以及与用户的约定，按照本隐私保护协议所述使用收集的信息，以向您提供更为优质的服务。
        </p>
        <ul>
          <li>
            <p>4.1 告知变动目的后征得同意的方式:</p>
            <p style="text-indent: 2em">
              我们将会在本隐私保护协议所涵盖的用途内使用收集的信息。如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。
            </p>
          </li>
        </ul>
      </div>
      <div style="padding: 16px">
        <p class="name">5.变更</p>
        <p class="comment" style="text-indent: 2em">
          我们可能会适时对本协议进行修订。当协议的条款发生变更时，我们会在您登录及版本更新时以推送通知、弹窗的形式向您展示变更后的协议。请您注意，只有在您点击弹窗中的同意按钮后，我们才会按照更新后的协议收集、使用、存储您的个人信息。
        </p>
        <ul></ul>
      </div>
      <div style="padding: 16px">
        <p class="name">6.其他</p>
        <p class="comment" style="text-indent: 2em">
          重庆市筑云科技有限责任公司《隐私政策》是重庆市筑云科技有限责任公司统一适用的一般性隐私条款，其中所规定的用户权利及信息安全保障措施均适用于BIMCC数字建造用户。如重庆市筑云科技有限责任公司《隐私政策》与本协议存在不一致或矛盾之处，请以本协议为准。
        </p>
        <ul></ul>
      </div>
      <div style="padding: 16px">
        <p class="name">7.与我们联系</p>
        <p class="comment" style="text-indent: 2em"></p>
        <ul>
          <li>
            <p></p>
            <p style="text-indent: 2em">
              当您有其他的投诉、建议相关问题时，请通过&nbsp;023-68682379与我们联系。
            </p>
          </li>
          <li>
            <p></p>
            <p style="text-indent: 2em">
              我们将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内予以回复。
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAgreement',
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
}
</script>
<style lang="less" scoped>
.name{
      font-size: 15px;
    font-weight: 500;
    color: #25282e;
}
.conment{
  margin-top: 8px;
    font-size: 15px;
    font-weight: 400;
    color: #25282e;
    line-height: 25px;
    padding-left: 16px;
    padding-right: 16px;
}
</style>
