<!--
 * @Description: 自定义登录页
 * @Author: luocheng
 * @Date: 2022-07-06 16:30:39
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-07-05 09:44:26
-->
<template>
  <div class="custom-login" v-loading="loading">
   <!-- <ScreenPage v-if="inited && editorType === 'dataview'"></ScreenPage> -->
		<!-- <Parser v-if="inited && editorType === 'page'" :canvasStyleOut="canvasStyle"></Parser> -->
		<Parser v-if="inited" :canvasStyleOut="canvasStyle"></Parser>
  </div>
</template>

<script>
// import ScreenPage from '@/components/parser/screen/Index';
import { dataInterface } from '@/apis/data';
import { isJSONStr } from '@/utils/tools';
import Parser from '@/components/parser/Index';
import { deepClone } from '@topology/core';

export default {
  name: 'CustomLogin',
  props: {
    pageUUID: {
      type: String,
      required: true,
      default: ''
    }
  },
  components: {
    Parser,
    // ScreenPage
  },
  provide() {
    return {
      EDITOR_pageUUID: this.pageUUID || ''
    }
  },
  data() {
    return {
      loading: true,
      inited: false,
      editorType: '',
      canvasStyle: {}
    }
  },
  created() {
    this.getViewData();
  },
  methods: {
   /**
		 * @desc: 获取dataView配置数据
		 */
		getViewData() {
			this.loading = true;
			this.$store.commit('clearConfigData');
			dataInterface({
				__method_name__: 'dataList',
				page_uuid: this.pageUUID || this.$route.query.pageUUID,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			}).then((res) => {
        this.loading = false;
        const { page_data } = res?.data?.data?.[0] || {};
        const {
          componentData,
          editorConfig,
          editorType,
          statusConfig = [],
          globalInterval = {}
        } = isJSONStr(page_data) ? deepClone(JSON.parse(page_data)) : deepClone(page_data);
        this.canvasStyle = editorConfig || {};
        sessionStorage.setItem('statusConfig', JSON.stringify(statusConfig));
        this.$store.commit('setGlobalInterval', globalInterval);
        this.editorType = editorType;
        this.$nextTick(() => {
          this.$store.commit('setConfigData', {
            componentData,
            editorConfig,
            editorType
          });
          this.inited = true;
        });
      }).catch(() => {
        this.loading = false;
      });
		},
  }
}
</script>

<style lang="less" scoped>
.custom-login{
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}
</style>