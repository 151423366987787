<!--
 * @Description: 濮阳工学院登录页
 * @Author: luocheng
 * @Date: 2022-06-16 17:28:03
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-13 14:40:54
-->
<template>
  <div class="pygxy-login">
    <article class="login-main">
      <el-form :model="loginForm" ref="loginForm" :rules="rules">
        <el-form-item prop="name">
          <el-input v-model="loginForm.name" placeholder="账号" clearable size="large"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="loginForm.password" placeholder="密码"
             clearable
             size="large"
             show-password
          ></el-input>
        </el-form-item>
        <section class="action">
          <el-button type="primary" size="large" @click="onLogin">登录</el-button>
        </section>
      </el-form>
    </article>
  </div>
</template>

<script>
import mixin from '@/custom-component/login/mixin';

export default {
  name: 'PYGXY',
  mixins: [mixin],
  data() {
    return {
      loginForm: {
        name: '',
        password: ''
      },
      rules: {
        name: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    onLogin() {
      this.$refs.loginForm.validate(valid => {
        console.log(valid, '11111111');
        if (valid) {
          this.$loading();
          try {
            this.doLogin({
              ...this.loginForm
            })
          } catch (err) {
            console.log(err, '22222')
            this.$loading().close();
            this.$message.error('登录失败，请联系管理员！');
          }
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.pygxy-login{
  height: 100vh;
  width: 100vw;
  min-width: 860px;
  min-height: 800px;
  background: #fff;
  background-image: url('https://static.bimcc.com/openim/pygxy_bg94F832243DAFF085456B88678EBB1B45.jpg');
  background-repeat: no-repeat;
  background-clip: border-box;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  .login-main{
    width: 400px;
    position: absolute;
    right: 200px;
    bottom: 350px;
    .el-form{
      .el-form-item{
        margin-bottom: 30px;
        .el-input{
          border: 6px;
        }
      }
      .action{
        width: 100%;
        display: flex;
        .el-button{
          flex: 1;
        }
      }
    }
  }
}
</style>