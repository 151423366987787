<!--
 * @Description: 登录页
 * @Author: luocheng
 * @Date: 2021-10-25 10:26:07
 * @LastEditors: zx
 * @LastEditTime: 2022-07-01 14:40:09
-->
<template>
  <div class="login">
   <article class="login-main">
     <LoginForm></LoginForm>
   </article>
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm';

export default {
  name: 'Login',
  components: {
    LoginForm
  }
}
</script>

<style lang="less" scoped>
.login{
  height: 100vh;
  width: 100vw;
  min-width: 860px;
  min-height: 800px;
  background: #fff;
  background-image: url('~@/assets/images/login/zssjzx_bg.jpg');
  background-repeat: no-repeat;
  background-clip: border-box;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  .login-main{
    width: 24%;
    height: 70%;
    position: absolute;
    right: 15%;
    top: 30%;
  }
}

</style>
